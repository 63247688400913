import React from 'react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { TextButton, Tooltip, TooltipSkin } from 'wix-ui-tpa/cssVars';
import {
  ChevronLeftSmall as ChevronLeft,
  ChevronRightSmall as ChevronRight,
} from '@wix/wix-ui-icons-common/on-stage';
import { classes, st } from './BackButton.st.css';
import { DataHooks } from './constants';
import { useHistory } from '../../Hooks/useHistory/useHistory';
import { BackButtonViewModel } from '../../ViewModel/backButtonViewModel/backButtonViewModel';

export interface BackButtonProps {
  viewModel?: BackButtonViewModel;
}

export const BackButton: React.FC<BackButtonProps> = ({ viewModel }) => {
  const { isRTL } = useEnvironment();
  const { back, length } = useHistory();
  const { experiments } = useExperiments();
  const isReduceLayoutShiftsOnCalendarPage = experiments.enabled(
    'specs.bookings.reduceLayoutShiftsOnCalendarPage',
  );

  const showBackButton =
    !!viewModel && (isReduceLayoutShiftsOnCalendarPage || length > 1);

  const handleOnClick = () => {
    if (!viewModel?.tooltipText) {
      back();
    }
  };

  const button = () => (
    <TextButton
      data-hook={DataHooks.BackButton}
      onClick={handleOnClick}
      className={classes.button}
      prefixIcon={
        isRTL ? <ChevronRight aria-hidden /> : <ChevronLeft aria-hidden />
      }
    >
      {viewModel!.label}
    </TextButton>
  );

  return (
    <div className={st(classes.root, { isRTL })}>
      {showBackButton &&
        (viewModel.tooltipText ? (
          <Tooltip
            skin={TooltipSkin.Wired}
            content={viewModel.tooltipText}
            className={classes.tooltip}
            data-hook={DataHooks.Tooltip}
            minWidth={200}
          >
            {button()}
          </Tooltip>
        ) : (
          button()
        ))}
    </div>
  );
};
