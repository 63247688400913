import React, { useEffect, useState } from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import BookingDetails from '../BookingDetails/BookingDetails';
import { SidebarViewModel } from '../../ViewModel/sidebarViewModel/sidebarViewModel';
import { FlowElements, useFlow } from '../../Hooks/useFlow';
import Login from '../Login/Login';
import { classes } from './Sidebar.st.css';
import { useExperiments } from '@wix/yoshi-flow-editor';

export interface SidebarProps {
  viewModel: SidebarViewModel;
}

function isSidebarBelowBody(sidebar: HTMLDivElement | null) {
  const body = sidebar?.previousSibling as HTMLElement;
  return sidebar?.offsetLeft === body?.offsetLeft;
}

const Sidebar: React.FC<SidebarProps> = ({
  viewModel: { sidebarTitle, bookingDetailsViewModel, loginViewModel },
}) => {
  const sidebarRef = useFlow(FlowElements.SIDEBAR);
  const [isCollapsed, setIsCollapsed] = useState<boolean>();
  const { experiments } = useExperiments();
  const isCollapseServiceDetailsEnabled = experiments.enabled(
    'specs.bookings.calendar.collapseServiceDetails',
  );

  useEffect(() => {
    const shouldBeCollapsed = isSidebarBelowBody(sidebarRef.current);
    setIsCollapsed(shouldBeCollapsed);
  }, [sidebarRef]);

  return (
    <div ref={sidebarRef} data-hook="sidebar" className={classes.root}>
      {!isCollapseServiceDetailsEnabled && (
        <SectionHeader title={sidebarTitle} />
      )}
      <BookingDetails
        viewModel={bookingDetailsViewModel}
        title={sidebarTitle}
        isCollapsed={isCollapsed}
      />
      <Login viewModel={loginViewModel} />
    </div>
  );
};

export default Sidebar;
