import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { FloatingDropdown, FloatingDropdownOptionProps } from 'wix-ui-tpa';
import {
  WidgetComponents,
  WidgetElements,
} from '../../../../../utils/bi/consts';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import { FilterViewModel } from '../../../ViewModel/filterViewModel/filterViewModel';
import { FiltersDataHooks } from './dataHooks.const';

import { classes, st } from './Filters.st.css';

export interface FloatingDropdownFilterProps {
  viewModel: FilterViewModel;
}

export const FloatingDropdownFilter: React.FC<FloatingDropdownFilterProps> = ({
  viewModel,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { onFilterChanged, onElementClicked } = useCalendarActions();
  const { id, label, options } = viewModel;

  const onChange = (selectedOption: FloatingDropdownOptionProps) => {
    onElementClicked(
      WidgetComponents.FILTER,
      WidgetElements.FLOATING_DROPDOWN,
      {
        filterType: id,
      },
    );
    onFilterChanged({ [viewModel.id]: [selectedOption.id] });
  };

  return (
    <div className={st(classes.floatingDropdownLocation, { isMobile })}>
      <FloatingDropdown
        data-hook={FiltersDataHooks.FLOATING_DROPDOWN_FILTER}
        onChange={onChange}
        options={options.map((option) => {
          return { id: option.value, value: option.label, isSelectable: true };
        })}
        label={t('location-picker.mobile.label', { label })}
        value={options.find((option) => option.selected)?.value}
      />
    </div>
  );
};

export default FloatingDropdownFilter;
